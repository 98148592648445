<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header justify-content-start">
        <button class="btn btn-control" @click="$emit('close')">
          <img alt="Return back" src="@/assets/icons/bold-icon previous.svg"/>
        </button>
        <h3 class="ms-2">{{ branchId !== null ? "Edit Branch" : "Add Branch" }}</h3>
      </div>
      <div class="modal-body">
        <page-spinner v-if="ui.loading"/>
        <div v-else>
          <div class="modal-body-container">
            <div class="licenses-item">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" v-model="branch.city"/>
              </div>
              <div class="form-group">
                <label>State</label>
                <multiselect
                  v-model="branch.state"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="states"
                  :searchable="true"
                  :show-labels="false"
                  class="w-50"
                  placeholder="State">
                </multiselect>
            </div>
            </div>
            <div class="licenses-item">
              <div class="form-group">
                <label>NMLS ID</label>
                <input type="text" class="form-control" v-model="branch.nmlsId"/>
              </div>
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" class="form-control" v-model="branch.phoneNumber">
              </div>
            </div>
            <div class="licenses-item">
              <div class="form-group">
                <label>Business Hours</label>
                <input type="text" class="form-control" v-model="branch.businessHours"/>
              </div>
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" v-model="branch.address">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button title="Submit" action="secondary-default" @click-btn="save" :loading="ui.saving" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "BranchActionModal",
  components: {
    Multiselect,
    PageSpinner: () => import("../../../components/pageSpinner.vue"),
    BaseButton,
  },
  props: {
    branchId: {default: null, required: false},
    states: {required: true},
  },
  data() {
    return {
      branch: {},
      ui: {
        loading: false,
        saving: false,
      },
    };
  },
  computed: {
    isEdit() {
      return this.branchId !== null;
    },
  },
  beforeMount() {
    if (this.isEdit) {
      this.getBranch();
    }
  },
  methods: {
    getBranch() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/public-branches/${this.branchId}`)
        .then((response) => {
          this.branch = response.data.branch
          this.ui.loading = false;
        })
    },
    save() {
      this.ui.saving = true;
      let formData = new FormData
      for (let key in this.branch) {
        formData.append(key, this.branch[key]);
      }

      if (this.branchId === null) {
        this.$http.post('/api/v1/public-branches', formData)
          .then(() => {
            this.ui.saving = false
            this.$emit('close', true)
          }).catch(() => this.ui.saving = false)
      } else {
        this.$http.post(`/api/v1/public-branches/${this.branchId}`, formData)
          .then(() => {
            this.ui.saving = false
            this.$emit('close', true)
          }).catch(() => this.ui.saving = false)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  padding-left: 0;

  &-input {
    margin: 0;
    padding: 0;
    min-width: 35px;
  }
}

.modal-body-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.licenses {
  &-item {
    display: flex;
    width: 100%;
    padding: 17px 0;

    .form-group {
      input {
        height: 40px;
      }

      textarea {
        min-height: 40px;
      }

      &:first-child {
        width: 30%;
        max-width: 30%;
      }

      &:nth-child(2) {
        width: 60%;
        max-width: 60%;
        margin-left: 12px;
        margin-right: 8px;
      }
    }

    .btn-control {
      width: 40px;
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      margin-top: 30.5px;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  &-add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;

    span {
      display: block;
      margin-left: 4px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

.modal-footer {
  .btn-outline-dark {
    border: 1px solid #000000;
    border-radius: 8px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .bg-green {
    min-width: 105px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
}
</style>
